import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {useTranslation} from "react-i18next";

import Button from '../../components/Button';
import Main from '../../components/Main';
import Header from '../../components/Header';
import Loader from '../../components/Loader/Loader';

import LearningMaterials from './EditingBlock/LearningMaterials';

import {useDownloadPdf, useFetchLessonStructure, useMutateStructure, usePrintPdf} from './lessonEditorQueries';

import routes from "../../utils/routes";

import {
	TImageMaterialStructure,
	TLearningMaterials,
	TLearningMaterialsStructure, TParagraph,
	TTextMaterialStructure, TWordMaterialStructure
} from './learningMaterialsTypes';

import classes from './learningMaterialsEditorPage.module.scss';

import printIconImg from './printIcon.svg';


interface LearningMaterialsEditorPageProps {

}

const LearningMaterialsEditorPage: FC<LearningMaterialsEditorPageProps> = () => {

	const navigate = useNavigate();
	const {t, i18n} = useTranslation();
	
	const sessionId = useParams<{ sessionId: string }>().sessionId as string;
	
	const [topic, setTopic] = useState<string>('');
	const [learningMaterialsStructure, setLearningMaterialsStructure] = useState<TLearningMaterialsStructure>({});
	
	const handleChange = useCallback((key: string, action: React.SetStateAction<TLearningMaterials>) => {
		setLearningMaterialsStructure(materials => ({
			...materials,
			[key]: typeof action === 'function' ? action(materials[key]) : action,
		}));
	}, [setLearningMaterialsStructure])
	
	const {fetchLessonStructure, isLessonStructureLoading} = useFetchLessonStructure();
	const {mutateStructure, isMutateStructureLoading} = useMutateStructure();
	const {downloadPdf, isPdfDownloading} = useDownloadPdf();
	const {printPdf, isPdfPrinting} = usePrintPdf();
	
	const handleDownload = useCallback(() => {
		downloadPdf({token: sessionId});
	}, [downloadPdf, sessionId]);
	
	const handlePrint = useCallback(() => {
		printPdf({token: sessionId});
	}, [printPdf, sessionId]);
	
	useEffect(() => {
		fetchLessonStructure({
			token: sessionId,
		}, {
			onSuccess: (data) => {
				setLearningMaterialsStructure(data.structure);
				setTopic(data.parameters.topic);
			}
		});
	}, [sessionId]);
	
	const handleRegenerate = useCallback(() => {
		mutateStructure({
			token: sessionId,
			structure: learningMaterialsStructure,
		}, {
			onSuccess: () => {
				navigate(routes.afterRegenerate())
			}
		})
	}, [sessionId, mutateStructure, learningMaterialsStructure]);
	
	const hasEditing = useMemo(() => {
		return hasEditingInLearningMaterialsStructure(learningMaterialsStructure);
	}, [learningMaterialsStructure]);
	
	const titles = useMemo(() => ({
		table_completion: t('learning_materials_editor.game_names.table_completion'),
		fill_in_game: t('learning_materials_editor.game_names.fill_in_game'),
		sequence_game: t('learning_materials_editor.game_names.sequence_game'),
		true_false: t('learning_materials_editor.game_names.true_false'),
		labeling_game: t('learning_materials_editor.game_names.labeling_game'),
		coloring_game: t('learning_materials_editor.game_names.coloring_game'),
	}), [i18n.language]);
	
	const descriptions = useMemo(() => ({
		table_completion: t('learning_materials_editor.game_descriptions.table_completion'),
		fill_in_game: t('learning_materials_editor.game_descriptions.fill_in_game'),
		sequence_game: t('learning_materials_editor.game_descriptions.sequence_game'),
		true_false: t('learning_materials_editor.game_descriptions.true_false'),
		labeling_game: t('learning_materials_editor.game_descriptions.labeling_game'),
		coloring_game: t('learning_materials_editor.game_descriptions.coloring_game'),
	}), [i18n.language]);
	
	const keys = Object.keys(learningMaterialsStructure);
	keys.sort((a, b) => {
		if (a === 'main_page') {
			return -1;
		} else if (b === 'main_page') {
			return 1;
		} else {
			return 0;
		}
	});
	
	if (isLessonStructureLoading) {
		return (
			<Main className={classes.main_loader}>
				<Loader/>
			</Main>
		)
	}
	
	return (
		<Main className={classes.main}>
			<Header/>
			<div className={classes.learning_materials_editor_page}>
				<div className={classes.header}>
					<h1 className={classes.title}>{topic}</h1>
					<div className={classes.actions}>
						<Button variant="text" onClick={handlePrint}>
							{isPdfPrinting ? <Loader size={20}/> : <img src={printIconImg} alt="print"/>}
							{t('common.print')}
						</Button>
						<Button
							variant="outline"
							disabled={!hasEditing || isMutateStructureLoading}
							onClick={handleRegenerate}
						>
							{isMutateStructureLoading && (
								<Loader size={20} width={1}/>
							)}
							{t('common.regenerate')}
						</Button>
						<Button
							disabled={hasEditing || isPdfDownloading}
							onClick={handleDownload}
						>
							{isPdfDownloading && (
								<Loader inverted size={20} width={1}/>
							)}
							{t('learning_materials_editor.save_lesson_as_pdf')}
						</Button>
					</div>
				</div>
				
				<div className={classes.content}>
					{keys.map(key => (
						<LearningMaterials
							key={key}
							editable={key === 'main_page'}
							title={titles[key]}
							subTitle={descriptions[key]}
							materials={learningMaterialsStructure[key]}
							setMaterials={materials => handleChange(key, materials)}
						/>
					))}
				</div>
				
				{/*<div className={classes.actions}>*/}
				{/*	*/}
				{/*</div>*/}
			</div>
		</Main>
	)
}

const hasEditingInMaterial = (material: TTextMaterialStructure | TImageMaterialStructure | TWordMaterialStructure) => {
	return material.update || material.regenerate;
}

const hasEditingInParagraph = (paragraph: TParagraph) => {
	
	if ('images' in paragraph) {
		for (const image of Object.values(paragraph.images)) {
			if (hasEditingInMaterial(image)) {
				return true;
			}
		}
	}
	
	if ('text' in paragraph) {
		for (const text of Object.values(paragraph.text)) {
			if (hasEditingInMaterial(text)) {
				return true;
			}
		}
	}
	
	if ('words' in paragraph) {
		for (const word of Object.values(paragraph.words)) {
			if (hasEditingInMaterial(word)) {
				return true;
			}
		}
	}
	
	return false;
}

const hasEditingInLearningMaterials = (learningMaterials: TLearningMaterials) => {
	
	if ('images' in learningMaterials) {
		for (const image of Object.values(learningMaterials.images)) {
			if (hasEditingInMaterial(image)) {
				return true;
			}
		}
	}
	
	if ('text' in learningMaterials) {
		if (hasEditingInMaterial(learningMaterials.text)) {
			return true;
		}
	}
	
	if ('paragraphs' in learningMaterials) {
		for (const paragraphs of Object.values(learningMaterials.paragraphs)) {
			if (hasEditingInParagraph(paragraphs)) {
				return true;
			}
		}
	}

	if ('words' in learningMaterials) {
		for (const word of Object.values(learningMaterials.words)) {
			if (hasEditingInMaterial(word)) {
				return true;
			}
		}
	}
	
	return false;
}

const hasEditingInLearningMaterialsStructure = (learningMaterials: TLearningMaterialsStructure) => {
	for (const item of Object.values(learningMaterials)) {
		if (hasEditingInLearningMaterials(item)) {
			return true;
		}
	}
	
	return false;
}

export default LearningMaterialsEditorPage;